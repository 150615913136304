.caja{

    box-shadow: 10px 10px 30px 10px rgba(0, 0, 0);
    border-color: blue;
    border-width: 3px;
   
}
.imgConsultoria{
    box-shadow: 20px 10px 30px 10px rgba(0, 0, 0);
    border-color: blue;
    border-width: 3px;
}

.texto{
    font-size: 70px;
    font-family: unset;
    margin-left: 10%;
}

.hr{
    height: 2px;
    width: 25%;
    background-color: rgba(72, 61, 234);
  
}

div .texto2{
    margin-left: 8%;
    margin-right: 8%;
    font-size: 30px ;
    margin-top: 5%;
}



.textoPlan{
    font-size: 40px;
    font-family: unset;
    text-align: center;
}

.textoPlan2{
    font-size: 30px ;
    text-align: center;
}

.textoPlan3{
    text-align: center;
    font-size: 25px ;
    font-family: initial;
    
}

.imgServicio{
 width: 110%;
 
}

.FONDO{
    background-color: rgba(72, 61, 234);
    
}

.TarjetaCard{
    height: 70vh;
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
}

.TarjetaCard:hover{
    background-color:  rgba(72, 61, 234, 0.5);
}

.amzu{
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 50px;
    text-align: center;
    color:  rgba(0, 0, 0);
   
    
   }


@media(max-width:800px){
    .texto{
        font-size: 30px !important;
    }
    .texto2{
        font-size: 15px !important;
    }
    .texto3{
        font-size: 10px !important;
    }
     .textoPlan{
        font-size: 30px !important;
    }
    .textoPlan2{
        font-size: 15px !important;
    }
    .textoPlan3{
        font-size: 20px !important;
    }
}