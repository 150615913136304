.Nosotros{
    height: 90vh;
}

.textoNosotros{
    font-family:Georgia, 'Times New Roman', Times, serif;
 font-size: 100px;
 text-shadow: 2px 2px 2px black ;
}

.TituloNoaotros{
    font-size: 50px;
    font-family: unset;
    font-weight: 600;
   
   
}
.textoNosotros1{
    margin-left: 8%;
    margin-right: 8%;
    font-size: 30px ;
    margin-top: 5%;
}

.hr{
    height: 2px;
    width: 25%;
    background-color: rgba(72, 61, 234);
  
}

.lineaVertical{
border-left:2px solid black;
margin-right:0px;
border-top:0px solid ;
border-bottom:0px solid ;
}

.lineaVertical2{
    border-right:2px solid black;
    margin-right:0px;
    border-top:0px solid ;
    border-bottom:0px solid ;
    }

.textoAlianza1{
    font-size: 30px;
}

.textoAlianza{
    font-size: 20px;
}


.textoEquipo{
    text-align: center;
    font-size: 30px;
}


@media(max-width:800px){
    .textoNosotros{
        font-size: 50px !important;
    }
    .textoNosotros1{
        font-size: 20px ;
   
    }

    .TituloNoaotros{
        font-size: 35px;
        margin-bottom: 20%;

    }
  
}

