.footer{
    background-color:  rgb(43, 42, 42);
    height: 10vh;
  
}

.footer1{  
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.3);
    box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.3);
    height: 50vh;
}

.textoFooter{
    margin-left: 10px;
    font-weight: 600;
    font-size: 17px;
    text-align: left;
    text-decoration: none;
    display: block;
    color: white;
    text-decoration: none !important;
    
}

.imgFooter{
    width: 250px;
}

.textoFooter2{
    margin-left: 10px;
    font-weight: 600;
    font-size: 25px;
    text-align: left;
    margin-top: 50px ;
    margin-bottom: 40px;
}



@media(max-width:800px){
    .footer{
        height: 10vh;
    }

    .footer1{  
        height: 100%;
    }
  
 }