.menu{
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.3);
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.3);
    height: 15vh;
    background-color: rgba(255, 255, 255, 0.8);
}
.textoMenu{
    font-size: 20px;
    font-weight: 400;
    margin-right: 5%;
}

.textInicio {
   color: black ;
     text-decoration: none !important;

}

.textInicio:hover{
    cursor: pointer;
    color: rgba(72, 61, 234);
 
}

.logo{
    width: 13%;
    margin-left: 5%;
}




@media(max-width:800px){
   .collapseMenu{
    background-color: rgba(255, 255, 255, 0.8);
}


.logo{
    width: 40%;
}
  
}