.contacto{
    width: 100%;
    height: 100vh;
    background-image: url(https://www.grupodigital.eu/wp-content/uploads/2017/06/cover-videoslider.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  
}


.TextoContacto{
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 100px;
    text-shadow: 2px 2px 2px black ;

   }

.formulario{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif ;
    color: rgba(72, 61, 234);
}   

.info{
    font-size: 25px;
    color: white;
    
}

.imput{
   border-top-color:white ;
   border-left-color:white ;
   border-right-color:white ;
   border-bottom-color:black ;
}

.imput:hover{
    border-bottom-color: rgba(72, 61, 234);
    background-color: rgba(72, 61, 234, 0.3);
}

@media(max-width:800px){
    .TextoContacto{
        font-size: 50px !important;
    }
    .formulario{
        padding: 2% !important;
    }
}