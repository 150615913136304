.inicio{
    width: 100%;
    height: 100vh;
    background-image: url(https://www.garzaconsultores.com/wp-content/uploads/slider-fianzas-1_garza-consultores.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
 
  
}

.textoInicio{
   width: 50%;
   color: white;
    font-size: 50px;
    text-align: center;
    text-shadow: 2px 2px 2px black ;
}

.textoInicio2{
    color: white;
    font-size: 40px;
    text-align: left;
 
    text-shadow: 2px 2px 2px black ;
}

.boton{
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 10px;
}

.SERVICIO{
    width: 100%;
    height: 30vh;
   background-image: url(https://www.aldaconsultores.es/wp-content/uploads/2018/12/consultores-toledo-.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   background-attachment: fixed;

}

.textoServicio{
    font-size: 60px;
    font-family: unset;
    font-weight: 600;
    margin-left: 5%;
    color: white;
    text-align: center;
    
}

.overDiv{
    overflow: hidden;
    
}

.imgCliente{
    
    width: 53%;
    
}

.imgMetodologia{
    width: 80%;
}


@media(max-width:800px){
    .textoServicio{
        font-size: 30px !important;
    }

    .textoInicio{
        font-size: 35px;
        text-align: center;
    }
    .textoInicio2{
        font-size: 20px;
        display: block;
    }
    
    
    .imgMetodologia{
    width: 100%;
    }
    .text3{
        font-size: 15px!important;
    }
   
}
.text3{
    margin-right: 5%;
    margin-left: 5%;
    font-size: 20px ;
 
    font-family: initial;
    
}

::-webkit-scrollbar{
    width: 12px;
}

::-webkit-scrollbar-thumb{
background: rgba(72, 61, 234);
border-radius: 10px;
}